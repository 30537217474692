






































.treps-web-layout {
  display: grid;
  grid-template-areas: 'title title title' 'left center right';
}
